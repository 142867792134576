<template>
  <router-view/>
</template>

<script>
	import { gAccount } from '@/data/Account'
	import {
		useRouter
	} from 'vue-router'
	import {
		getCurrentInstance
	} from 'vue'
	import {
			IsLoginNew
		} from '@/api/request';
		
	export default {
		setup(props) {
			const _this = getCurrentInstance().proxy;
		 	function IsLogin(){
				const router = useRouter()
				IsLoginNew().then(res=>{
					console.log(res)
					if(!res) return;
					if(res.data.Mid==0){
						gAccount.remove_token()
						gAccount.remove_accoun()
						_this.$cookies.remove("Admin-Token") 
						router.push({path: "/loginView"})
					}
					// else{
					// 	gAccount.set_token(res.data.Token)
					// 	gAccount.set_accoun(res.data.AdminName)
					// 	this.$cookies.set("Admin-Token",res.data.Token,-1); 
					// 	this.$router.push({path: "/"})
					// }
				})
			};
			function setCookie(cname, cvalue, exdays) {
			    var d = new Date();
			    d.setTime(d.getTime() + (exdays*24*60*60*1000));
			    var expires = "expires="+d.toUTCString();
			    document.cookie = cname + "=" + cvalue + "; " + expires;
			}
			// IsLogin()
		},
		methods: {
			
		}
	}
	
</script>
<style>
#app {
  font-family: PingFangSC-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
	margin: 0;
}
.loading_loading .path{
	stroke:#000000 !important;
}
.loading_loading .el-loading-text{
	color: #000000 !important;
}
::-webkit-scrollbar{
	  width: 6px;
	}
	/* 定义滚动条轨道 */
	::-webkit-scrollbar-track{
	  -webkit-box-shadow: inset 0 0 6px ;
	  border-radius: 10px;
	  background-color: #FFF;
	}
	/* 滑块 */
	::-webkit-scrollbar-thumb {
	  border-radius: 10px;
	  -webkit-box-shadow: inset 0 0 6px;
	  background-color: #AAA;
	}
</style>
