<template>
  <div class="bottom">
    <div class="t">
      <div>中文（简体）</div>
      <div>
        <a href="/pc/#/AboutView">品牌介绍</a>
      </div>
      <div>
        <a href="/pc/#/shopView">专卖店</a>
      </div>
      <div>
        <a href="/pc/#/contactView">联系我们</a>
      </div>
      <div>
        <a href="javascript:void(0)">会员权益</a>
      </div>
      <div>
        <a href="/pc/#/returnsView">常见问题</a>
      </div>
      <div>
        <el-popover placement="top" trigger="hover" :width="80" popper-class="el_popover">
          <div>
            <el-image
              style="width: 80px;height: 80px;"
              src="https://www.d8d.cn/rev9/img/QR_code/wx-gzh.jpg"
            ></el-image>
          </div>
          <template #reference>
            <div style="cursor: pointer;">关注微信公众号</div>
          </template>
        </el-popover>
      </div>
      <div>
        <a href="https://weibo.com/u/6251573067" target="_blank">关注微博</a>
      </div>
      <div>
        <a
          href="https://www.douyin.com/user/MS4wLjABAAAA7h7sjK5nDnhaJrAcClJ5raTT7V5lCFmetH4s8P1Wk7kHO81uqVmU5Upg0K_J4Rh7"
          target="_blank"
        >关注抖音</a>
      </div>
      <div>
        <a href="https://d8dfspj.tmall.com" target="_blank">天猫</a>
      </div>
    </div>
    <div class="b">
      <span>帝八帝（北京）服饰有限公司</span>
      <span>
        <a href="http://beian.miit.gov.cn" target="_blank">京ICP备2024047840号-2</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
  data() {
    return {}
  },
  methods: {
    div_href(e) {
      window.open(e)
    },
  },
  mounted() {},
}
</script>

<style scoped>
.bottom {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  height: 60px;
  padding: 50px 0 30px 0;
}
.bottom .t {
  display: flex;
  align-items: center;
}
.bottom .t > div > span {
  cursor: pointer;
}
.bottom .t > div {
  margin-right: 20px;
}
.bottom .t a {
  text-decoration: none;
  color: #000000;
}

.bottom .b {
  text-align: left;
}
.bottom .b span {
  margin-right: 20px;
}
.bottom .b a {
  text-decoration: none;
  color: #000000;
}
</style>
<style>
.el_popover {
  min-width: 80px !important;
}
</style>